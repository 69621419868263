import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "dataTables_wrapper dt-bootstrap4 no-footer" }
const _hoisted_2 = { class: "table-responsive" }
const _hoisted_3 = {
  class: "text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0",
  role: "row"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "fw-bold text-gray-600" }
const _hoisted_6 = { class: "odd" }
const _hoisted_7 = {
  colspan: "7",
  class: "dataTables_empty"
}
const _hoisted_8 = {
  key: 0,
  class: "overlay-layer card-rounded bg-dark bg-opacity-5"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" }
const _hoisted_11 = {
  key: 0,
  class: "dataTables_length",
  id: "kt_customers_table_length"
}
const _hoisted_12 = { class: "col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", {
        class: _normalizeClass([[_ctx.loading && 'overlay overlay-block'], "table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"]),
        id: "kt_customers_table",
        role: "grid"
      }, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (cell, i) => {
              return (_openBlock(), _createElementBlock("th", {
                key: i,
                onClick: ($event: any) => (
                  _ctx.sort(
                    cell.sortingField ? cell.sortingField : cell.key,
                    cell.sortable
                  )
                ),
                class: _normalizeClass([
                  cell.name && 'min-w-125px',
                  cell.sortable !== false && 'sorting',
                  _ctx.tableHeader.length - 1 === i && 'text-end',
                  _ctx.currentSort ===
                    `${cell.sortingField ? cell.sortingField : cell.key}desc` &&
                    'sorting_desc',
                  _ctx.currentSort ===
                    `${cell.sortingField ? cell.sortingField : cell.key}asc` &&
                    'sorting_asc',
                ]),
                tabindex: "0",
                rowspan: "1",
                colspan: "1",
                style: {"cursor":"pointer"}
              }, _toDisplayString(cell.name), 11, _hoisted_4))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", _hoisted_5, [
          (_ctx.getItems.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getItems, (item, i) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: i,
                  class: "odd"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (cell, i) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: i,
                      class: _normalizeClass({ 'text-end': _ctx.tableHeader.length - 1 === i })
                    }, [
                      _renderSlot(_ctx.$slots, `cell-${cell.key}`, { row: item }, () => [
                        _createTextVNode(_toDisplayString(item[_ctx.prop]), 1)
                      ])
                    ], 2))
                  }), 128))
                ]))
              }), 128))
            : _createCommentVNode("", true),
          _createElementVNode("template", null, [
            _createElementVNode("tr", _hoisted_6, [
              _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.emptyTableText), 1)
            ])
          ])
        ]),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[2] || (_cache[2] = [
              _createElementVNode("div", {
                class: "spinner-border text-primary",
                role: "status"
              }, [
                _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
              ], -1)
            ])))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        (_ctx.enableItemsPerPageDropdown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("label", null, [
                _createElementVNode("select", {
                  name: "kt_customers_table_length",
                  class: "form-select form-select-sm form-select-solid",
                  onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setItemsPerPage && _ctx.setItemsPerPage(...args)))
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("option", { value: "10" }, "10", -1),
                  _createElementVNode("option", { value: "25" }, "25", -1),
                  _createElementVNode("option", { value: "50" }, "50", -1),
                  _createElementVNode("option", { value: "100" }, "100", -1)
                ]), 32)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_el_pagination, {
          "current-page": _ctx.pagination.page,
          "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination.page) = $event)),
          onCurrentChange: _ctx.currentPageChange,
          "page-size": _ctx.pagination.rowsPerPage,
          layout: "prev, pager, next",
          total: _ctx.pagination.total,
          "hide-on-single-page": true,
          background: ""
        }, null, 8, ["current-page", "onCurrentChange", "page-size", "total"])
      ])
    ])
  ]))
}