import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header border-0" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-flex btn-light-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_add_payment"
}
const _hoisted_4 = { class: "svg-icon svg-icon-3" }
const _hoisted_5 = { class: "card-body pt-0 pb-5" }
const _hoisted_6 = {
  href: "#",
  class: "btn btn-sm btn-light btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_7 = { class: "svg-icon svg-icon-5 m-0" }
const _hoisted_8 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4",
  "data-kt-menu": "true"
}
const _hoisted_9 = { class: "menu-item px-3" }
const _hoisted_10 = { class: "menu-item px-3" }
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card pt-4 ${_ctx.cardClasses}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title" }, [
        _createElementVNode("h2", null, "Payment Records")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen035.svg" })
          ]),
          _cache[0] || (_cache[0] = _createTextVNode(" Add payment "))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Datatable, {
        "table-header": _ctx.tableHeader,
        "table-data": _ctx.tableData,
        "rows-per-page": 5,
        "enable-items-per-page-dropdown": false
      }, {
        "cell-invoice": _withCtx(({ row: payment }) => [
          _createTextVNode(_toDisplayString(payment.invoice), 1)
        ]),
        "cell-status": _withCtx(({ row: payment }) => [
          _createElementVNode("span", {
            class: _normalizeClass(`badge badge-light-${payment.status.state}`)
          }, _toDisplayString(payment.status.label), 3)
        ]),
        "cell-amount": _withCtx(({ row: payment }) => [
          _createTextVNode(_toDisplayString(payment.amount), 1)
        ]),
        "cell-date": _withCtx(({ row: payment }) => [
          _createTextVNode(_toDisplayString(payment.date), 1)
        ]),
        "cell-actions": _withCtx(({ row: payment }) => [
          _createElementVNode("a", _hoisted_6, [
            _cache[2] || (_cache[2] = _createTextVNode("Actions ")),
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr072.svg" })
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_router_link, {
                to: "/apps/customers/customer-details",
                class: "menu-link px-3"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("View")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.deleteRecord(payment.invoice)),
                class: "menu-link px-3"
              }, "Delete", 8, _hoisted_11)
            ])
          ])
        ]),
        _: 1
      }, 8, ["table-header", "table-data"])
    ])
  ], 2))
}