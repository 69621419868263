import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body py-0" }
const _hoisted_2 = { class: "d-flex flex-wrap flex-stack mb-5" }
const _hoisted_3 = { class: "d-flex flex-wrap" }
const _hoisted_4 = { class: "border border-dashed border-gray-300 w-150px rounded my-3 p-4 me-6" }
const _hoisted_5 = { class: "fs-1 fw-bolder text-gray-800 lh-1" }
const _hoisted_6 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_7 = { class: "border border-dashed border-gray-300 w-125px rounded my-3 p-4 me-6" }
const _hoisted_8 = { class: "fs-1 fw-bolder text-gray-800 lh-1" }
const _hoisted_9 = { class: "svg-icon svg-icon-1 svg-icon-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card ${_ctx.cardClasses}`)
  }, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header border-0" }, [
      _createElementVNode("div", { class: "card-title" }, [
        _createElementVNode("h2", null, "Earnings")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "fs-5 fw-bold text-gray-500 mb-4" }, " Last 30 day earnings calculated. Apart from arranging the order of topics. ", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _cache[0] || (_cache[0] = _createElementVNode("span", {
                "data-kt-countup": "true",
                "data-kt-countup-value": "6,840",
                "data-kt-countup-prefix": "$"
              }, "6,840$", -1)),
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr066.svg" })
              ])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "fs-6 fw-bold text-muted d-block lh-1 pt-2" }, "Net Earnings", -1))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, [
              _cache[2] || (_cache[2] = _createElementVNode("span", {
                class: "",
                "data-kt-countup": "true",
                "data-kt-countup-value": "16"
              }, "16", -1)),
              _cache[3] || (_cache[3] = _createTextVNode("% ")),
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_inline_svg, { src: "icons/duotune/arrows/arr065.svg" })
              ])
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "fs-6 fw-bold text-muted d-block lh-1 pt-2" }, "Change", -1))
          ]),
          _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"border border-dashed border-gray-300 w-150px rounded my-3 p-4 me-6\"><span class=\"fs-1 fw-bolder text-gray-800 lh-1\"><span data-kt-countup=\"true\" data-kt-countup-value=\"1,240\" data-kt-countup-prefix=\"$\">1,240$</span><span class=\"text-primary\">--</span></span><span class=\"fs-6 fw-bold text-muted d-block lh-1 pt-2\">Fees</span></div>", 1))
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("a", {
          href: "#",
          class: "btn btn-sm btn-light-primary flex-shrink-0"
        }, "Withdraw Earnings", -1))
      ])
    ])
  ], 2))
}