
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "events",
  props: {
    cardClasses: String,
  },
  components: {},
  setup() {
    const events = ref([
      {
        event:
          'Invoice <a href="#" class="fw-bolder text-gray-900 text-hover-primary me-1">#LOP-45640</a> has been <span class="badge badge-light-danger">Declined</span>',
        date: "20 Dec 2021, 5:30 pm",
      },
      {
        event:
          'Invoice <a href="#" class="fw-bolder text-gray-900 text-hover-primary me-1">#DER-45645</a> status has changed from <span class="badge badge-light-info me-1">In Progress</span> to <span class="badge badge-light-primary">In Transit</span>',
        date: "24 Jun 2021, 5:20 pm",
      },
      {
        event:
          '<a href="#" class="text-gray-600 text-hover-primary me-1">Melody Macy</a> has made payment to <a href="#" class="fw-bolder text-gray-900 text-hover-primary">#XRS-45670</a>',
        date: "05 May 2021, 11:05 am",
      },
      {
        event:
          'Invoice <a href="#" class="fw-bolder text-gray-900 text-hover-primary me-1">#KIO-45656</a> status has changed from <span class="badge badge-light-succees me-1">In Transit</span> to <span class="badge badge-light-success">Approved</span>',
        date: "20 Dec 2021, 6:43 am",
      },
      {
        event:
          '<a href="#" class="text-gray-600 text-hover-primary me-1">Max Smith</a> has made payment to <a href="#" class="fw-bolder text-gray-900 text-hover-primary">#XRS-45670</a>',
        date: "10 Nov 2021, 9:23 pm",
      },
      {
        event:
          'Invoice <a href="#" class="fw-bolder text-gray-900 text-hover-primary me-1">#SEP-45656</a> status has changed from <span class="badge badge-light-warning me-1">Pending</span> to <span class="badge badge-light-info">In Progress</span>',
        date: "22 Sep 2021, 5:30 pm",
      },
      {
        event:
          '<a href="#" class="text-gray-600 text-hover-primary me-1">Emma Smith</a> has made payment to <a href="#" class="fw-bolder text-gray-900 text-hover-primary">#SDK-45670</a>',
        date: "25 Jul 2021, 8:43 pm",
      },
      {
        event:
          '<a href="#" class="text-gray-600 text-hover-primary me-1">Melody Macy</a> has made payment to <a href="#" class="fw-bolder text-gray-900 text-hover-primary">#XRS-45670</a>',
        date: "05 May 2021, 2:40 pm",
      },
      {
        event:
          '<a href="#" class="text-gray-600 text-hover-primary me-1">Emma Smith</a> has made payment to <a href="#" class="fw-bolder text-gray-900 text-hover-primary">#OLP-45690</a>',
        date: "25 Oct 2021, 10:30 am",
      },
      {
        event:
          'Invoice <a href="#" class="fw-bolder text-gray-900 text-hover-primary me-1">#WER-45670</a> is <span class="badge badge-light-info">In Progress</span>',
        date: "10 Mar 2021, 9:23 pm",
      },
    ]);

    return {
      events,
    };
  },
});
