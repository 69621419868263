
import { defineComponent } from "vue";

export default defineComponent({
  name: "earnings",
  props: {
    cardClasses: String,
  },
  components: {},
});
