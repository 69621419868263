import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-light-primary"
}
const _hoisted_4 = { class: "svg-icon svg-icon-3" }
const _hoisted_5 = { class: "card-body py-0" }
const _hoisted_6 = {
  class: "table align-middle table-row-dashed fs-6 text-gray-600 fw-bold gy-5",
  id: "kt_table_customers_events"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "pe-0 text-gray-600 text-end min-w-200px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card pt-4 ${_ctx.cardClasses}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title" }, [
        _createElementVNode("h2", null, "Events")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/files/fil021.svg" })
          ]),
          _cache[0] || (_cache[0] = _createTextVNode(" Download Report "))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("table", _hoisted_6, [
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (event, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", {
                class: "min-w-400px",
                innerHTML: event.event
              }, null, 8, _hoisted_7),
              _createElementVNode("td", _hoisted_8, _toDisplayString(event.date), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ], 2))
}